import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useWindowDimensions from "../utils/windowSize";
import { ACTIVE_PAGE } from "../components/nav/utils";
import DropdownMenu from "../components/DropdownMenu";
import { useEffect, useState } from "react";
import {
  DeliverableIframe,
  IframeWrapper,
  NoScrollContainer,
} from "../components/styled-components/Globals/GlobalStyledComponents";
import {
  PageNavDirection,
  PageNavNext,
  PageNavNextName,
  PageNavigation,
} from "../components/styled-components/Globals/PageNavigation";

const getIndex = (searchQuery: string) => {
  const urlQuery = /^(?:\?view=)(.*)/.exec(searchQuery);
  const query = urlQuery ? urlQuery[1].replaceAll("+", " ") : null;
  if (!query) return 0;

  const titles = threeDLinks.map((link) => link.title);
  if (titles.includes(query))
    return titles.findIndex((title) => title === query);
  else return 0;
};

export default function ThreeDPage() {
  const { width } = useWindowDimensions();
  const [linkIndex, setLinkIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setLinkIndex(getIndex(window.location.search));
    }
  }, []);

  const handleClick = (title: string, index: number) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      url.searchParams.set("view", title);
      window.history.pushState({}, "", url.toString());
      setLinkIndex(index);
    }
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.THREED}>
      <Seo
        title="BIM Modeling Services | Robotic Imaging"
        description="Sample Models from BIM Services provided by Robotic Imaging."
      />

      <header className="site__header">
        <div className="site__header__button-container-tags">
          {threeDLinks.map((link, index) => (
            <button
              key={link.title}
              onClick={() => handleClick(link.title, index)}
              disabled={linkIndex === index}
              className={`site__header__button${
                linkIndex === index ? "-disabled" : ""
              }`}
            >
              {link.title}
            </button>
          ))}
        </div>

        <DropdownMenu
          index={linkIndex}
          setIndex={setLinkIndex}
          options={threeDLinks}
        />
      </header>

      <NoScrollContainer>
        <IframeWrapper>
          <DeliverableIframe
            title="three-d-drawing"
            src={linkIndex !== null ? threeDLinks[linkIndex].url : ""}
          />
        </IframeWrapper>

        <PageNavigation>
          <PageNavNext to="/2D" title="Next page">
            <div>
              <PageNavDirection>Next Page</PageNavDirection>
              <ChevronRightIcon />
            </div>
            {width && width >= 650 ? (
              <PageNavNextName>2D Imaging</PageNavNextName>
            ) : null}
          </PageNavNext>
        </PageNavigation>
      </NoScrollContainer>
    </SiteWrapper>
  );
}

export const threeDLinks = [
  {
    title: "LOD200: Commercial Warehouse",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99fa178086df447d02?mode=embed",
  },
  {
    title: "LOD300: Commercial Restaurant",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99599f3dc72151fdbd?mode=embed",
  },
  {
    title: "LOD300: Institutional: Education",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c9911b82f6ea18d5dc0?mode=embed",
  },
  {
    title: "LOD400: Commercial Adaptive Re Use",
    url: "https://myhub.autodesk360.com/ue2a16417/g/shares/SHd38bfQT1fb47330c9953bdda080590e1bf?mode=embed",
  },
  {
    title: "LOD400: Commercial Retail",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c996fefe687637e4daf?mode=embed",
  },
  {
    title: "LOD400: Residential Single-Family Detached",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99b0155a43b174e5cd?mode=embed",
  },
  {
    title: "LOD400: Industrial Storage",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c9945e8cd37338c2250?mode=embed",
  },
  {
    title: "LOD200: Residential Kitchen",
    url: "https://roboimg.autodesk360.com/shares/public/SHd38bfQT1fb47330c99ea0da9fc190fac00?mode=embed",
  },
];
